.FcButton {
  box-sizing: border-box;
  background-color: #2EC5B6;
  color: white;
  padding: .8em 1.5em;
  border-radius: 8px;
  font-weight: normal;
  cursor: pointer;
  transition: .2s;
}

.FcButton:active {
  transform: scale(.99);
}