.FcButtonOutline {
  box-sizing: border-box;
  border: 2px solid #2EC5B6;
  padding: calc(.8em - 2px) calc(1.5em - 2px);
  border-radius: 8px;
  font-weight: normal;
  cursor: pointer;
  transition: .2s;
}

.FcButtonOutline:active {
  transform: scale(.99);
}