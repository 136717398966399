.Banners {
  display: flex;
  justify-content: space-evenly;
  margin-top: 32px; 
  margin-bottom: 32px;
  flex-direction: row;
}

.Banner-caption {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Banner-caption-tick-row {
  display: flex;
  align-items: center;
}

.Banner-caption-tick {
  width: 14px;
  box-sizing: border-box;
  margin-right: 4px;
  margin-left: -7px;
}

@media screen and (max-width: 760px) {
  .Banners {
    flex-direction: column;
    align-items: center;
  }
}