.LoginButton {
  margin-top: 1em;
}

.LoginContent {
  padding-bottom: 1em;
}

.LoginLoadingText {
  padding: .7em 1em;
}
