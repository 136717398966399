.MainContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: -48px;
}

.ScreenShot {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
}

.Buttons {
  margin: 4em 0;
}