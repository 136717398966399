.TickListText {
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: black;
  white-space: pre-wrap;
  padding: 0 1rem;
  margin: 0 auto;
}

.ListTick {
  display: inline-flex;
  align-self: center;
  width: 22px;
  box-sizing: border-box;
  padding-top: 6px;
  margin-right: 12px;
}