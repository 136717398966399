@import url(https://dcsaascdn.net/aurora/dist/mitra/shoper_dark/2.0.1.313/css/aurora.min.css);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap);
body {
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", 
  "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.btn {
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", 
  "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.Button {
  display: flex; 
  justify-content: center; 
  align-items: center;
}

.TextField {
  margin-top: 8px;
}

.RegisterButton {
  margin-top: 1em;
}

.RegisterContent {
  padding-bottom: 1em;
}

.RegisterLoadingText {
  padding: .7em 1em;
}

.RegisterMessageBox {
  margin-top: 1em;
}

.RegisterTos {
  margin-top: 1em;
}

.LoginButton {
  margin-top: 1em;
}

.LoginContent {
  padding-bottom: 1em;
}

.LoginLoadingText {
  padding: .7em 1em;
}

.Social {
  display: flex;
  justify-content: center;
  grid-gap: 12px;
  gap: 12px;
  margin-top: 2em;
}
.PrivacyPolicySection {
  margin-top: 2em;
  text-align: center; 
}

.PrivacyPolicyLink {
  color: #363636;
}

.PrivacyPolicyLink:link {
  color: #363636;
  text-decoration: none;
}

.PrivacyPolicyLink:visited {
  color: #363636;
  text-decoration: none;
}
.FcButton {
  box-sizing: border-box;
  background-color: #2EC5B6;
  color: white;
  padding: .8em 1.5em;
  border-radius: 8px;
  font-weight: normal;
  cursor: pointer;
  transition: .2s;
}

.FcButton:active {
  -webkit-transform: scale(.99);
          transform: scale(.99);
}
.FcButtonOutline {
  box-sizing: border-box;
  border: 2px solid #2EC5B6;
  padding: calc(.8em - 2px) calc(1.5em - 2px);
  border-radius: 8px;
  font-weight: normal;
  cursor: pointer;
  transition: .2s;
}

.FcButtonOutline:active {
  -webkit-transform: scale(.99);
          transform: scale(.99);
}
.Banner {
  box-sizing: border-box;
  border-radius: 8px;
  background-color: white;
  padding: 1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  max-height: 180px;
  width: 250px;
}

.Banner-greyBorder {
  border: 1px solid #c4c4c4;
}

.Banner-fcBorder {
  border: 1px solid #2EC5B6;
}
.Banners {
  display: flex;
  justify-content: space-evenly;
  margin-top: 32px; 
  margin-bottom: 32px;
  flex-direction: row;
}

.Banner-caption {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Banner-caption-tick-row {
  display: flex;
  align-items: center;
}

.Banner-caption-tick {
  width: 14px;
  box-sizing: border-box;
  margin-right: 4px;
  margin-left: -7px;
}

@media screen and (max-width: 760px) {
  .Banners {
    flex-direction: column;
    align-items: center;
  }
}
.TickListText {
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: black;
  white-space: pre-wrap;
  padding: 0 1rem;
  margin: 0 auto;
}

.ListTick {
  display: inline-flex;
  align-self: center;
  width: 22px;
  box-sizing: border-box;
  padding-top: 6px;
  margin-right: 12px;
}
.Benefits {
  line-height: 2.6rem;
}
.MainContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: -48px;
}

.ScreenShot {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
}

.Buttons {
  margin: 4em 0;
}
