@import url("https://dcsaascdn.net/aurora/dist/mitra/shoper_dark/2.0.1.313/css/aurora.min.css");
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap');


body {
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", 
  "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.btn {
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", 
  "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
