.Banner {
  box-sizing: border-box;
  border-radius: 8px;
  background-color: white;
  padding: 1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  max-height: 180px;
  width: 250px;
}

.Banner-greyBorder {
  border: 1px solid #c4c4c4;
}

.Banner-fcBorder {
  border: 1px solid #2EC5B6;
}