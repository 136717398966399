.RegisterButton {
  margin-top: 1em;
}

.RegisterContent {
  padding-bottom: 1em;
}

.RegisterLoadingText {
  padding: .7em 1em;
}

.RegisterMessageBox {
  margin-top: 1em;
}

.RegisterTos {
  margin-top: 1em;
}
