.PrivacyPolicySection {
  margin-top: 2em;
  text-align: center; 
}

.PrivacyPolicyLink {
  color: #363636;
}

.PrivacyPolicyLink:link {
  color: #363636;
  text-decoration: none;
}

.PrivacyPolicyLink:visited {
  color: #363636;
  text-decoration: none;
}